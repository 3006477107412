html, body, #root, .full-height {height: 100%}
body {overflow: hidden}
.form-group textarea {
    font-size: 12px;
}

.form-group label {
    font-weight: bold;
}
textarea{
    resize: none;
}

.console {
    position: absolute;
    background: #333;
    bottom: 0;
    left: 0;
    right: 0;
    top: calc(100% - 150px);
    overflow: hidden;
}

.console textarea{
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 5px;
    color: #eee;
    font-size: 11px;
    font-family: "Courier New";
    resize: none;
}